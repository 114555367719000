.internalheroblock{
    position: relative;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF5E7 100%);
    padding: 156px 0 140px;
}

.quater-circle{
    position:absolute;
    
bottom: 0;
left: 0;

}

.full-circle {
    position: absolute;
    right: 95px;
    top: 131px;
  }


.internalheroblock h1{
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #171B26;
    margin-bottom: 14px;
}

.internalheroblock p{
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #3D424D;
    max-width: 800px;
    margin: 0 auto;
}



.offer ul{
  list-style: none;
  background-color: white;
  padding: 6px;
  border-radius: 24px;
  margin: 0;
  margin-right: 18px;
}

.offer ul li{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #3D424D;
  padding: 6px 16px;
  cursor: pointer;
  border-radius: 18px;
}

.offer ul li.selected{
  background: #FEC168;
}

.offer span{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ED2D2D;
  
}



@media screen and (max-width: 1200px) {

    .internalheroblock {
        padding: 40px 0 60px;
      }

      .quater-circle {
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
      }
      .full-circle {
        position: absolute;
        right: 95px;
        top: 131px;
        display: none;
      }

      .internalheroblock h1 {
        font-size: 28px;
        line-height: 36px;
      }

      .internalheroblock p {
        font-size: 16px;
        line-height: 26px;
      }

      .pos {
        padding-top: 53px;
        padding-left: 8px;
        padding-right: 8px;
      }

      .pos-tile.lightspeed .logo {
        max-width: 63px;
        margin: 0 auto 35px;
      }
      .pos .logo {
        max-width: 120px;
        margin: 0 auto 21px;
      }
      .pos .col-xl-3 {
        width: 50%;
      }

      .pos-tile.lightspeed {
        padding: 32px 10px 10px;
      }

      .pos-tile {
        padding: 20px 10px 10px;
        margin-bottom: 20px;
      }















}