@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Inter';
    text-decoration: none !important;
}
html, body{
  overflow-x: hidden;
}
body{
  background-color: #fffdfa !important;
  text-decoration: none !important;
}
.numbers-image{
  margin-top: 100px;
}
a button{
  text-decoration: none !important;
  color:#000 !important; 
}
.home-learn{
  background-color: #f7f7f7 !important;
  padding-top: 100px;
  padding-bottom:100px;
}
.featurebtn{
  margin-top:30px !important;
}
@media only screen and (max-width: 600px) {
  .home-feature{
    background-color: #fffdfa !important;
  }
  .home-feature-2{
    background-color: #f7f7f7 !important;
  }
  .home-operators{
    background-color: #fffdfa !important;
  }
  }
header .navbar-toggler:focus{
  box-shadow: none !important;
}
#navbarNav .css-13cymwt-control{
  width:90px;
}
#navbarNav .css-t3ipsp-control{
  width:90px;
}
.css-1nmdiq5-menu{
  width:90px !important;
}
.css-1nmdiq5-menu div{
  transition:0.4s !important;
}
.nav-right {
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1200px) {
  .button{
    margin-left:0px !important;
  }
}
.button{
  margin-left:10px;
}
.btn-custom{
    border-radius: 8px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.form-select, option {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  cursor: pointer;
}

.btn-yellow{
    background: #FEC168 !important;
    border-radius: 8px !important;
    padding: 12px 20px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border: 1px solid transparent
}

.btn-yellow:hover {
    background: #ffb03a !important;
}

.btn-hollow-blue{
    padding: 16px 32px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #05235F;
    border: 1px solid #05235F !important;
    border-radius: 8px;
    background-color: transparent !important;
}

.btn-hollow-blue:hover{
    color: #fff !important;
    border: 1px solid transparent !important;
    background-color: #05235F !important;
}

.btn-solid-blue{
    margin-right: 28px;
    background: #05235F !important;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 12px 20px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border: 1px solid #05235F !important;
}

.btn-solid-blue:hover{
    background: transparent !important;
    color: #05235F !important;
}



.container-lg{
    max-width: 1212px !important;
}

/* Header Css */


nav .container-lg{
    padding: 0 21px;
}
.navbar-brand{
    margin-right: 144px !important;
}

.nav-item{
    margin-right: 21px;
    
}
.nav-item a:hover {
  color: #FEC168 !important;
}
.nav-item a{
    color: #171B26 !important;

}

.navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
    /* padding-right: 124px !important; */
    align-items: center !important;
}

nav button{
    border-radius: 8px !important;
    padding: 12px 20px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.lang{
    margin-right: 32px;
}


.navbar-expand-lg {
    padding-top: 23px !important;
    background: transparent !important;
  }



/* Footer Css */

footer{
    background: #05235F;
    color: #EBEBEB;
    padding: 120px 0;
}

footer .footer-logo{
    margin-bottom: 24px;
    display: block;
}

footer ul{
    list-style: none;
    font-size: 16px;
    line-height: 26px;
    padding: 0;
}


footer ul a{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #EBEBEB;
    text-decoration: none;
    
}

footer ul a:hover{ 
    color: #FEC168;
}

footer h5{
    font-size: 16px;
    margin-bottom: 35px;
}

footer .social{
    padding-right: 20px;
padding-left: 21px;
}

footer .social p{
    margin-bottom: 35px;
}

footer .social .social-icons li{
    margin-right: 32px;
}

footer .company-1{
    padding-left: 87px;
}

footer .company-1 li, footer .company-2 li{
    padding-bottom: 10px;
}

footer .company-2{
    padding-left: 51px;
}

footer .contact{
padding-right: 71px;
}

footer .contact .icon{
    padding-left: 2px;
    margin-right: 14px;
}

footer .contact p{
    
    margin-bottom: 0;
}

footer .contact li{
    padding-bottom: 15px;
}


.copyright{
  background: #05235F;
  
}

.copyright .container-lg{
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 60px;
}


.copyright p{
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
}


/* Home page css */

.home-intro{
    padding-top: 146px;
    padding-bottom:  108px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFBF5 100%);
}

.home-intro .container-lg{
    max-width: 1337px;
}


.home-intro .text {
    padding-top: 62px;
  }

.home-intro .home-hero-banner {
  position: relative;
  right: 10px;
  text-align:right
}

.home-intro h1{
    font-weight: 700;
    font-size: 52px;
    line-height: 40px;
    color: #000000;
    margin-bottom: 22px;
}
.home-intro p{
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #3D424D;
    max-width: 547px;
    margin-bottom: 47px;
}

.home-intro .btn{
    padding: 16px 32px !important ;
}


.home-ordering {
    padding: 70px 0px;
  }

.home-ordering .preheader{
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.08em;
    color: #05235F;
    margin-bottom: 14px;
    display: block;
}

.ordering-banner {
    position: relative;
    left: -29px;
}

.home-ordering .text {
    padding: 43px 3px 0 102px;
}

.home-ordering h2 {
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    letter-spacing: -0.02em;
    
    color: #171B26;
    margin-bottom: 26px;
}


.home-ordering p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #3D424D;
    margin-bottom: 46px;
}


.home-ordering .btn{
    padding: 16px 32px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #05235F;
    border: 1px solid #05235F;
    border-radius: 8px;
}

.home-learn .text {
    padding-right: 15px;
    padding-left: 9px;
  }


.home-learn h2 {
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    letter-spacing: -0.02em;
    
    color: #171B26;
    margin-bottom: 23px;
}


.home-learn p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #3D424D;
    margin-bottom: 23px;
}

.home-learn ul {
    padding: 0;
    list-style: none;
    margin-bottom: 28px;
}

.home-learn ul li {
    position: relative;
    padding: 0 0 20px 1px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #3D424D;
    display: flex;
}

.home-learn ul li span {
    display: block;
    margin-right: 10px ;
}


.home-learn .btn{
    padding: 11px 23px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #05235F;
    border: 1px solid #05235F;
    border-radius: 8px;
}

.learn-banner {
    position: relative;
    text-align: right;
    padding-right: 9px;
  }

  .home-exp {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .home-exp .text {
    padding: 100px 84px 0 45px;
  }

  .home-exp .preheader{
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.08em;
    color: #05235F;
    margin-bottom: 14px;
    display: block;
}


  .home-exp h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.02em;
    
    color: #171B26;
    margin-bottom: 57px;
}


.home-exp ul {
    padding: 0;
    list-style: none;
}

.home-exp ul li {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    padding: 24px 24px 11px;
    margin-bottom: 14px;
}

.home-exp ul li h5{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171B26;
    margin-bottom: 10px;
}
.home-exp ul li p{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #3D424D;
}


.home-exp .tile-icon {
    margin-right: 31px;
  }


  .home-exp .ordering-banner {
    position: relative;
    text-align: right;
    left: unset;
    padding-right: 12px;
  }
  .home-exp .ordering-banner span {
    position: absolute;
    top: 106px;
    left: -141px;
z-index: 2;
  }

  .ordering-banner .mobile-container {
    max-width: 344px;
    position: relative;
    margin: 0 auto;
    border: 8px solid #FFFFFF;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 22px 0;
    position: relative;
  }
  .ordering-banner 
  .menu-item-img img{
    width: 100%;
  }

  .ordering-banner 
  .menu-item-description {
    font-size: 11px;
    margin-bottom: 8px;
    
  }
  .ordering-banner 
  .menu-item-price {
    font-size: 11px;
  }
  .ordering-banner .container-lg{
    padding-top: 25px;
  }

  .home-exp  .ordering-banner  ul li {
    padding: 24px 10px 11px;
  }
  .ordering-banner
  .cartSection li p {
    font-size: 14px !important;
    margin: 0;
  }

  .ordering-banner 
  .menu-item-name {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .ordering-banner .logo {
    margin-bottom: 0;
    margin-right: 92px;
  }
  .ordering-banner
  ul.menu-list li {
    padding: 0;
    background: transparent;
  }

  .ordering-banner .start-mobile-app::before {
    content: '';
    width: 100%;
    height: 705px;
    background: black;
    position: absolute;
    left: 0;
    opacity: 0.5;
    z-index: 0;
    border-radius: 24px;
  }

  .ordering-banner
  ul.sub-menu-list li {
    padding: 0;
    background: transparent;
  }
  .ordering-banner
  .start-mobile-app button {
    z-index: 999;
    position: relative;
    top: 46%;
    left: -22%;
    transform: translate(-64%);
    animation: myAnim 4s ease 0s infinite normal forwards;
  }



  .home-feature {
    padding: 50px 0 60px;
    background: #F7F7F7;

  }

  .home-feature h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.02em;
    
    color: #171B26;
    margin-bottom: 11px;

}




.home-feature p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #3D424D;
    margin-bottom: 65px;
}



.home-feature .features-tab.nav.nav-tabs {
    justify-content: end;
    border: 0;
    padding-top: 53px;
    padding-right: 0;
  }


  .home-feature  .nav-item {
    margin-right: 6px;
  }

  .home-feature  .tab-content {
    padding: 54px 10px 0 103px;
    max-height: 523px !important;
  }

  .home-feature .nav-tabs .nav-link.active {
    background: #05235F !important;
    border-radius: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    
    color: #FFFFFF !important;
    border-color: transparent !important;
  }

  .home-feature .nav-tabs .nav-link:focus, .home-feature .nav-tabs .nav-link:hover {
    border-radius: 20px;
  }

  .home-feature .nav-tabs .nav-link {
    border-radius: 20px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    color: #212121 !important; 
 
  }

  .home-feature .accordion-button:not(.collapsed) {
    background: #fff !important;
    
    color: #000000;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    box-shadow: none !important;
    padding: 24px 25px 0;
    margin-bottom: 3px;


  }

  .home-feature .accordion-button{
    background: #fff !important;
    
    color: #000000;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    padding: 24px 25px ;
  }

  .home-feature .accordion-button span{
    margin-right: 24px;
}

  .home-feature .accordion-button:focus{
    border: none !important;
    box-shadow: none !important;
  }


  .home-feature .accordion-item{
    border: none !important;
    background-color: #fff !important;
    border-radius: 12px !important;
    margin-bottom: 16px !important;
  }

  .home-feature .accordion-item:last-child{
    margin: 0 !important;
  }



  .home-feature  .accordion-body{
    padding: 15px 20px 27px 90px;
  }

  .home-feature .accordion-item h2{
    margin-bottom: 0;
  }




  .home-feature-2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .home-feature-2 h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #000000;
    margin-bottom: 13px;
  }

  .home-feature-2 p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #3D424D;
    max-width: 559px;
    margin: 0 auto;
    margin-bottom: 58px;
  }

  .row.feature-tiles {
    padding-left: 10px;
  }

  
  .feature-tile {
    padding: 32px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    margin-bottom: 23px;
  }

  .feature-tile .icon {
    margin-bottom: 30px;
  }

  .feature-tile  .icon.easy-icon {
    margin-bottom: 45px;
  }

  .feature-tile h4 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    
    color: #171B26;
  }

  .feature-tile p {
    margin-bottom: 0;
  }

  .home-operators h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #000000;
    margin-bottom: 13px;
  }


  .home-operators p{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #3D424D;
    max-width: 766px;
    margin: 0 auto;
    margin-bottom: 63px;
  }


  .home-operators {
    padding-top: 70px;
    /* background: linear-gradient(105.41deg, #FFFAF0 0%, #F5F9FF 100%); */
    background-color: #f7f7f7;
    padding-bottom: 210px;
    margin-bottom: -170px;
}

  .home-operators .row.op-tiles{
    padding-left: 9px;
  }

  .op-buttons a{
    color: #05235F;
    text-decoration: none;
  }

  .op-buttons a:hover{
    color: #FEC168;
    transition: 0.2s;
  }

  .home-operators h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #171B26;
    margin-bottom: 17px;
  }

  .home-operators .op-tile {
    padding: 24px 24px 1px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.06);
    border-radius: 0px 0px 12px 12px;
    margin-bottom: 59px;
  }
  
  .op-tiles ul {
    padding: 0;
    list-style: none;
  }

  .op-tiles li {
    padding: 0 0 10px 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #3D424D;
    display: flex;
  }

  .op-tiles li span {
    display: block;
    margin-right: 12px;
  }
  
  
  /* .home-operators .btn {
    margin-right: 28px;
    background: #05235F !important;
    color: #fff;
  } */

  .home-customer h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #171B26;
    margin-bottom: 49px;
  }

  .home-customer {
    padding-top: 113px;
  }

  .home-customer .customer-tile .img {
    margin-bottom: 17px;
  }

  .home-customer {
    padding-top: 113px;
    padding-bottom: 119px;
  }

  .home-customer p{
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #171B26;
    margin-bottom: 32px;
  }

  .home-customer .customer-tile {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    padding: 47px 32px 40px 36px;
  }

  .home-customer .customer {
    padding: 22px 5px 0 0;
    border-top: 1px solid #EBEBEB;

  }

  .home-customer .detail {
    width: 100%;
    padding-left: 17px;
    padding-top: 8px;
  }

  .home-customer .detail p {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #171B26;
    font-style: normal;
    margin-bottom: 3px;
  }

  .home-customer .detail span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3D424D;
  }

  .home-customer .star p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #171B26;
    font-style: normal;
    margin: 0 7px 0 0;
  }

  .num-banner {
    position: relative;
  }

  .num-banner span {
    position: absolute;
    right: 209px;
    top: 65px;
    z-index: -1;
  }

  .home-numbers h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.02em;
    
    color: #171B26;
    margin-bottom: 17px;
  }

  .home-numbers .text {
    padding-top: 65px;
    padding-left: 9px;
  }

  .home-numbers ul {
    padding: 32px 40px 36px 42px;
    list-style: none;
    background-color: #fff;
    box-shadow: 8px 8px 16px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    margin-left: 20px;
    /* width: 63%; */
  }
  .home-numbers .text > p {
    margin-bottom: 59px;
}

.num-banner {
  padding-top: 44px;
}


.home-numbers .tile-icon {
    margin-right: 17px;
    padding-top: 5px;
  }

  .home-numbers h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: #05235F;
    margin-bottom: 3px;
  }

  .home-numbers li {
    margin-right: 79px;
  }

  .home-numbers li p {
    margin: 0;
  }


  .home-numbers {
    padding-bottom: 148px;
  }


  .home-ready .container-lg {
    background: #FFC268;
    border-radius: 16px;
    padding: 96px 10px 92px 91px;
  }


  .home-ready{
    position: relative;
  }
.home-ready p{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #171B26;
    margin: 0;
    padding-right: 254px;
}

.home-ready::before {
    content: '';
    width: 100%;
    height: 200px;
    background: #05235F;
    position: absolute;
    top: 161px;
    z-index: -1;
    left: 0;
  }


  .home-ready .btn.btn-solid-blue {
    margin: 37px 0 0 11px;
    padding: 16px 40px !important;
  }



  @media screen and (max-width: 1200px) {
    .home-exp h2 {
      font-weight: 600;
      font-size: 34px;
      line-height: 48px;
      letter-spacing: -0.02em;
      
      color: #171B26;
      margin-bottom: 57px;
    }

    .nav-right button{
      margin-top: 10px;
    }
    .nav-item {
      margin-right: 0;
    }
    .lang {
      margin-right: 0;
    }
    .navbar-brand {
      margin-right: 0 !important;
    }
    nav button{
      border: 0 !important;
      padding-right: 0 !important;
      padding-top: 8px !important;
    }
    .nav-right.d-flex{
      display: block !important;
    }
    .home-intro h1{
      font-size: 36px;
      line-height: 25px;
    }
    .navbar-expand-lg {
      padding-top: 10px !important;
    }

    .navbar-brand img {
      width: 124px;
    }

    .home-intro {
      padding-top: 0;
      padding-bottom: 61px;
    }
    .home-intro .text {
      padding-top: 42px;
      padding-left: 9px;
      margin-bottom: 39px;
    }

    .home-intro p {
      font-size: 16px;
      line-height: 26px;
      max-width: 100%;
      margin-bottom: 22px;
    }

    .home-intro .btn {
      padding: 11px 22px !important;
    }

    img{
      width: 100%;
    }
    .home-intro .home-hero-banner {
      right: unset;
      padding-left: 8px;
      padding-right: 10px;
    }
    
    .home-ordering .text {
      padding: 0 3px 0 8px;
    }

    .home-ordering {
      padding: 58px 0px;
    }
    .home-ordering h2 {
      font-size: 28px;
      line-height: 32px;
    }
    .home-ordering p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 22px;
    }
    
    .home-ordering .btn {
      padding: 11px 31px !important;
      margin-bottom: 2px;
    }

    

    .home-learn {
      padding-bottom: 54px;
    } 
    
    .learn-banner {
      padding-right: 9px;
      padding-left: 9px;
      margin-bottom: 34px;
    }
    .home-learn h2 {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 23px;
    }
    .home-learn ul li span {
        min-width: 22px;
    }
    .home-learn ul li:last-child span img {
      position: relative;
      top: 27%;
  }
  
  .home-feature h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 11px;
  }
  .home-feature {
    padding: 33px 2px 62px;
  }

  .home-feature p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .home-feature .features-tab.nav.nav-tabs {
    justify-content: center;
    margin-left: -8px;
margin-right: -8px;
padding-top: 20px;
margin-bottom: -20px;
  }
  .home-feature .nav-item {
    margin-right: 5px;
  }

  .home-feature .accordion-button:not(.collapsed) {
    font-size: 20px;
    line-height: 28px;
    padding: 24px 20px 0;
    margin-bottom: 3px;
  }

  .home-feature .tab-content {
    padding: 54px 0px 0 0;
    max-height: 523px !important;
  }

  .home-feature .accordion-button span {
    margin-right: 21px;
  }


  .home-feature .accordion-body {
    padding: 15px 39px 27px 85px;
  }


  .home-feature .accordion-button {
    font-size: 20px;
    line-height: 28px;
    padding: 24px 18px;
  }

  .home-feature-2 h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 13px;
  }



  .home-feature-2 {
    padding-top: 45px;
    padding-bottom: 15px;
  }


  .feature-tile .icon {
    margin-bottom: 30px;
    max-width: 62px;
  }



  .home-feature-2 p {
    margin-bottom: 34px;
  }

  .row.feature-tiles {
    padding-left: 10px;
    padding-right: 10px;
  }
  .feature-tile p {
    margin-bottom: 0;
  }

  .home-operators h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 13px;
  }

  .home-operators p {
    margin-bottom: 41px;
  }

  .row.op-tiles .col-xl-4{
    padding-right: 20px;
  }

  .home-operators .op-tile {
    margin-bottom: 24px;
  }
  .row.op-buttons{
    justify-content: space-between;
  }

  .op-buttons .btn.btn-solid-blue {
    margin-right: 37px;
  }
  .op-buttons{
    margin-top: 24px !important;

  }
  .home-customer {
    padding-top: 25px;
    padding-bottom: 0;
  }

  .home-customer h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 23px;
  }

  .home-customer .customer-tile .img {
    margin-bottom: 17px;
    max-width: 37px;
  }
  .home-customer .customer-tile {
    padding: 47px 28px 40px 29px;
    margin: 3px;
  }
  .avatar {
    min-width: 59px;
  }

  .home-customer .customer {
    padding: 25px 0px 0 0;
  }
  
  .star img {
    min-width: 16px;
  }


  
  .home-customer .slide.selected.previous {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
  }

  .home-customer .dot.selected {
    background: #05235F !important;
    border: 0 !important;
    box-shadow: none !important;
  }
  .home-customer .dot{
    background: #D2D3D6 !important;
    box-shadow: none !important;
    width: 12px;
  height: 12px;
  }

  .home-customer .carousel.carousel-slider {
    padding-bottom: 54px;
  }

  .home-numbers h2 {
    font-size: 28px;
    line-height: 36px;
    padding-right: 35px;
    margin-bottom: 14px;
  }
  .home-numbers .text {
    padding-top: 44px;
    padding-left: 9px;
  }
  .home-numbers ul {
    flex-wrap: wrap;
    padding: 32px 20px 36px 20px;
    margin-left: -9px;   
     width: 63%;
  }

  .home-numbers h5 {
    font-size: 24px;
    line-height: 100%;
    color: #05235F;
    margin-bottom: 3px;
  }
  .home-numbers li {
    margin-right: 15px;
    min-width: 133px;
  }

  .home-numbers .tile-icon {
    margin-right: 10px;
    padding-top: 0px;
  }


  .home-numbers li:first-child {
    margin-bottom: 20px;
  } 

  

  .home-ready {
    position: relative;
    padding: 0 20px;
  }


  .home-ready p {
    font-size: 24px;
    line-height: 32px;
    padding-right: 0;
  }

  .home-ready .container-lg {
    padding: 24px;
  
  }
  .home-ready .btn.btn-solid-blue {
  
      margin: 37px 0 0 0;
      width: 100%;
  
  }

  .home-numbers {
    padding-bottom: 130px;
  }


  footer {
    padding: 60px 0;
  }

  footer .footer-logo {
    max-width: 144px;
  }

  footer .social p {
    margin-bottom: 35px;
    padding-right: 60px;
  }
  footer .company-1 {
    padding-left: 20px;
  }

  footer ul {
    list-style: none;
    font-size: 16px;
    line-height: 26px;
    padding: 0;
    margin-bottom: 40px;
  }
  footer .company-2 {
    padding-left: 20px;
  }

  footer .contact {
    padding-left: 20px;
  }

  nav .form-select {
    margin-bottom: 10px;
  }




  .ordering-banner {
    position: relative;
    left: unset;
  }
  .feature-tile {
    height: 94%;
  }

  .num-banner span {
    position: absolute;
    right: 117px;
    top: 175px;
    z-index: -1;
    width: 100%;
  }


}
@media screen and (max-width:900px) {

  .home-exp .text {
    padding: 0 00 45px;
  }
  .home-exp 
  .col-md-7 , .home-exp 
  .col-md-5{
    width: 50%;
  }

  .home-exp h2 {
    font-weight: 600;
    font-size: 29px;
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #171B26;
    margin-bottom: 40px;
  }

}


@media screen and (max-width:767px) {
  .home-exp{
    display: none;
  }
  .ordering-banner {
    display: none;
  }

  .home-ordering .row {
    flex-direction: column-reverse;
  }

  .home-learn .row {
    flex-direction: column-reverse;
  }
  .num-banner {
    display: none;
  }


  .row.customer-tiles.desktop {
    display: none;
  }

  .home-numbers ul {
    width: 100%;
}


}


.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	right:100px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 0px 0px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
.whatsapp{
  margin-top:8px;
  margin-left: 2px;
  width: 32px !important;
}

@media only screen and (max-width: 480px) {
  .float {
    right: 80px;
    bottom: 15px;
    width:55px;
    height:55px;
  }
  .whatsapp{
    margin-top:5px;
  }
}

.cc-nsge{
  box-shadow: 2px 2px 3px #999;
}

.featurebtn{
  margin-top:50px !important;
}

.feature-tile .icon.easy-icon{
  margin-bottom:30px!important;
}

.compare-pricing tbody, .compare-pricing td{
  padding: 15px 0 !important;
}

.compare-pricing h2{
  margin-bottom: 30px !important;
}

@media only screen and (max-width: 600px) {
  .compare-pricing tbody, .compare-pricing td{
    padding: 7px 0 !important;
  }
}

.priceimage{
  margin-left: 10px;
}

.is-hidden{
  display: none;
}

.feature-banner{
  position: absolute;
  right: 60%;
  -webkit-box-shadow: 0px 0px 21px -11px rgba(0,0,0,0.56);
  -moz-box-shadow: 0px 0px 21px -11px rgba(0,0,0,0.56);
  box-shadow: 0px 0px 21px -11px rgba(0,0,0,0.56);
}

@media only screen and (max-width: 1200px) {
  .feature-banner{
    position: absolute;
    left: 10px;
    width: 40%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .feature-banner{
  display: none;
  }
  .functions{
    text-align: center;
  }
}

@media only screen and (max-width: 980px) {
  .tab-content{
    margin-bottom: 70px !important;
  }
}

@media only screen and (max-width: 400px) {
  .tab-content{
    margin-bottom: 100px !important;
  }
}