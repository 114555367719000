.contact-tile {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(8, 41, 19, 0.04);
    border-radius: 8px;
    padding: 50px;
    text-align: center;
}


.contact-tile.email {
    background: #FFFFFF;
    box-shadow: 0px 20px 60px -5px rgba(88, 97, 224, 0.15);
    border-radius: 8px;
}

.contact-tile .icon{
    text-align: center;
    margin-bottom: 32px;
}

.contact-tile h6{
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #212833;
    margin-bottom: 13px;
}

.contact-tile p{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #636466;
    margin-bottom: 35px;
}

.contact-tile a{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #636466;
    text-decoration: none;
}

.contact-tile a:hover {
    color: #05235F;
}

.contact-tile.email a{
    text-decoration: underline;
}

.contact-main {
    padding-top: 123px;
    padding-bottom: 60px;
}

.contact-form {
    padding-bottom: 130px;
  }

.contact-form h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 112%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #212833;
    margin-bottom: 57px;
}

.contact-form li{
    font-weight: 400;
    font-size: 18px;
    line-height: 168%;
    color: #505050;
    display: flex;
    padding-bottom: 19px;
}

.contact-form li:last-child {
    padding-right: 10px;
  }

.contact-form ul{
    list-style: none;    
    padding: 0;
}
.contact-form .text {
    padding-left: 8px;
    padding-right: 147px;
}


.contact-form span {
    display: block;
    margin-right: 16px;
}

.contact-form label{
    font-weight: 600;
    font-size: 16px;
    line-height: 136%;
    color: #212833;
    margin-bottom: 12px;
}

.contact-form input,.contact-form select,.contact-form  textarea{
    font-weight: 400;
    font-size: 16px;
    line-height: 136%;
    color: #636466;
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    background-color: transparent;
    padding: 18px 20px;
    margin-bottom: 21px !important;
}

.contact-form input:focus-visible,select:focus-visible, textarea:focus-visible{
    border: 1px solid #05235F;
}

.contact-form select, option{
    cursor: pointer;
}





@media only screen and (max-width: 1200px){
    .contact-tile .icon {
        max-width: 64px;
        margin: 0 auto 34px;
      }
      .contact-main {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      .contact-form h2 {
        font-size: 28px;
        line-height: 112%;
      }
      .contact-form .text {
        padding-left: 8px;
        padding-right: 21px;
      }


      .contact-form  .col-6{
        width: 100% !important;
      }
      .contact-form {
        padding-bottom: 60px;
      }
      .contact-tile h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 132%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #212833;
        margin-bottom: 13px;
      }
      .contact-tile {
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(8, 41, 19, 0.04);
        border-radius: 8px;
        padding: 50px 27px;
        text-align: center;
      }
      .contact-tile img {
        height: 60px;
      }
}

.col-xl-4{
  margin-bottom: 20px;
}