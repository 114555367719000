
.faq {
    padding-top: 120px;
    padding-bottom: 100px;
    position: relative;
  }

  .faq::before {
    content: '';
    width: 100%;
    height: 1082px;
    background: #F7F7F7;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .faq h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #171B26;
    margin-bottom: 15px;
  }

  .faq  p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #3D424D; 
    margin: 0 auto 55px;
    max-width: 745px;
  }

  .faq .accordion {
    padding: 0 107px;
  }

  .faq .accordion-button {
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: #171B26;
    padding: 25px 32px;
  }

  .faq .accordion-header {
    margin-bottom: 0 !important;
  }

  .faq .accordion-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(23, 27, 38, 0.04);
    border-radius: 8px !important;
    border: none;
    margin-bottom: 16px;
  }


  .faq .accordion-button:not(.collapsed) {
    padding: 25px 32px 2px;
    background: #fff;
    color: #171B26;
    box-shadow: none;
  }

  .faq .accordion-body {
    padding: 15px 34px 22px;
  }